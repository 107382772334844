const footerButtonBackToTopElement = document.querySelector('.footer__button-back-to-top');

if (footerButtonBackToTopElement) {
  footerButtonBackToTopElement.addEventListener('click', () => {
    document.scrollingElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });
}
