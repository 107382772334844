class GlobalMessage {
  constructor(message, error = false) {
    let successMessageElement = document.createElement('div');
    if (typeof message === 'object') {
      successMessageElement = message;
    } else if (typeof message === 'string' && message.length > 0) {
      successMessageElement.setAttribute('role', 'alert');
      successMessageElement.classList.add('global-message');
      if (error) {
        successMessageElement.classList.add('-error');
      }
      successMessageElement.textContent = message;
      const mainElement = document.querySelector('main');
      document.querySelector('body').insertBefore(successMessageElement, mainElement);
    }
    // successMessageElement.addEventListener('click', () => {
    //   successMessageElement.parentNode.removeChild(successMessageElement);
    // });
  }
}

[...document.querySelectorAll('.global-message')].forEach((element) => new GlobalMessage(element));

export default GlobalMessage;
