import GlobalMessage from './GlobalMessage';
import Popup from './Popup';

/* eslint-disable no-inner-declarations */
const element = document.querySelector('.translation-files');

if (element) {
  const containerElement = element.querySelector('.translation-files__container');
  const fileElement = element.querySelector('input[name="new-file"]');
  const { maxSize } = fileElement.dataset;
  const { id } = element.dataset;
  const url = `/portal/api/translation/${id}/files`;

  function showError(message) {
    // eslint-disable-next-line no-new
    new GlobalMessage(message, true);
    console.error(message);
  }

  function handleResponse(response) {
    containerElement.classList.remove('-loading');
    if (response.ok) {
      return response.json();
    }
    showError(`Server-Fehler: ${response.statusText}`);
    return false;
  }

  function niceSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  function createDeletePopupElement(filename) {
    const popupElement = document.createElement('section');
    popupElement.setAttribute('id', 'datei-loeschen');
    popupElement.setAttribute('tabindex', '0');
    popupElement.classList.add('popup');
    popupElement.classList.add('-dialog');
    popupElement.innerHTML = `
      <form class="popup__container form popup-form" action="${url}" data-method="DELETE">
        <input type="hidden" name="filename" value="${filename}">
        <header class="popup__header">
          <h2>Datei löschen</h2>
        </header>
        <p>Datei <em>${filename}</em> löschen?</p>
        <footer class="popup__footer">
          <a href="#!">abbrechen</a>
          <button type="submit" class="-red">Datei löschen</button>
        </footer>
      </form>
    `;
    return popupElement;
  }

  function createUploadPopupElement(files) {
    const popupElement = document.createElement('section');
    popupElement.setAttribute('hidden', '');
    popupElement.setAttribute('tabindex', '0');
    popupElement.classList.add('popup');
    popupElement.innerHTML = `
      <div class="popup__container form popup-form" tabindex="0">
        <header class="popup__header">
          <h2>Datei-Upload</h2>
        </header>
        <ul class="file-uploads"></ul>
        <footer class="popup__footer">
          <button type="submit">schließen</button>
        </footer>
      </div>
    `;
    const buttonElement = popupElement.querySelector('button');
    const ulElement = popupElement.querySelector('ul');

    const popup = new Popup(popupElement);
    popup.open();

    buttonElement.addEventListener('click', () => {
      popup.close();
    });

    [...files].forEach((file) => {
      const niceFileSize = niceSize(file.size);
      const liElement = document.createElement('li');
      liElement.dataset.id = file.name;
      liElement.innerHTML = `<strong>${file.name}</strong> <small>(${niceFileSize})</small>`;
      ulElement.appendChild(liElement);
    });
    return popupElement;
  }

  function createLiElement(fileData) {
    const liElement = document.createElement('li');
    liElement.innerHTML = `
      <a href="${fileData.url}" target="_blank"><strong>${fileData.filename}</strong></a><br>
      <small>Dateigröße: ${fileData.niceSize}<br>Hochgeladen von <em>${fileData.user}</em> am ${fileData.date}</small>
      <button type="button" class="button-icon -delete -red -small">
        <span class="tooltip">Löschen</span>
      </button>
    `;
    const buttonElement = liElement.querySelector('button');
    buttonElement.addEventListener('click', () => {
      const popupElement = createDeletePopupElement(fileData.filename);
      const popup = new Popup(popupElement);
      popup.onDelete = function () {
        liElement.remove();
      };
      document.body.appendChild(popupElement);
      popup.open();
    });
    return liElement;
  }

  function addErrorElementToLiElement(liElement, message) {
    liElement.classList.add('-error');
    const errorElement = document.createElement('span');
    errorElement.setAttribute('role', 'alert');
    errorElement.classList.add('file-uploads__error');
    errorElement.innerHTML = message;
    liElement.appendChild(errorElement);
  }

  function showFile(fileData) {
    containerElement.appendChild(createLiElement(fileData));
  }

  function showFiles(data) {
    containerElement.innerHTML = '';
    data.files.forEach((fileData) => showFile(fileData));
  }

  function getFiles() {
    fetch(url, {
      credentials: 'same-origin',
    }).then((response) => handleResponse(response)).then((data) => {
      showFiles(data);
    }).catch((exception) => showError(`Fehler: ${exception.message}`));
  }

  function uploadFile(file) {
    const data = new FormData();
    data.append('new-file', file);

    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      body: data,
    }).then((response) => handleResponse(response)).then((data) => {
      fileElement.value = '';
      if (data.success === true) {
        showFile(data.file);
      } else {
        throw data;
      }
      return data;
    });
  }

  function uploadFiles(files) {
    const filesCount = files.length;
    let completedUploads = 0;
    const popupElement = createUploadPopupElement(files);
    document.body.appendChild(popupElement);
    [...files].forEach((file) => {
      const liElement = popupElement.querySelector(`li[data-id="${file.name}"]`);
      if (file.size > maxSize) {
        const message = `Die Datei ist zu groß (maximal ${niceSize(maxSize)})`;
        addErrorElementToLiElement(liElement, message);
      } else {
        liElement.classList.add('-loading');
        uploadFile(file).then((data) => {
          liElement.classList.remove('-loading');
          liElement.classList.add('-success');
          completedUploads += 1;
          if (completedUploads === filesCount) {
            popupElement.remove();
          }
        }).catch((exception) => {
          liElement.classList.remove('-loading');
          addErrorElementToLiElement(liElement, exception.message);
        });
      }
    });
  }

  getFiles();

  fileElement.addEventListener('change', () => {
    uploadFiles(fileElement.files);
  });
  containerElement.addEventListener('click', () => {
    if (containerElement.children.length === 0) {
      const event = document.createEvent('MouseEvents');
      event.initEvent('click', true, false);
      fileElement.dispatchEvent(event);
    }
  });
  containerElement.addEventListener('dragleave', (event) => {
    containerElement.classList.remove('-dragover');
  });
  containerElement.addEventListener('dragover', (event) => {
    containerElement.classList.add('-dragover');
  });
  containerElement.addEventListener('dragover', (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }, false);
  containerElement.addEventListener('drop', (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { files } = event.dataTransfer; // FileList object.

    uploadFiles(files);
    containerElement.classList.remove('-dragover');
  }, false);
}
