import GlobalMessage from './GlobalMessage';

/* eslint-disable no-inner-declarations */
const element = document.querySelector('.edit-translation');

if (element) {
  const url = '/portal/api/translation';
  const { id } = element.dataset;
  const formElement = element.querySelector('form');
  const formElementsToBeAutosaved = [...formElement.elements].filter((formElementElement) => {
    formElementElement.initialValue = formElementElement.value;
    return (formElementElement.type === 'text' || formElementElement.type === 'textarea' || formElementElement.type === 'select-one');
  });
  const styleSelectElements = element.querySelectorAll('.edit-translation__style select');
  const translationsOrgElement = element.querySelector('[name="translationsOrg"]');
  const translationsOrgOtherElement = element.querySelector('[name="translationsOrgOther"]');
  const buttonsContainerElement = element.querySelector('.edit-translation__buttons-container');
  const buttonDeleteElement = buttonsContainerElement.querySelector('button[data-action="delete"]');
  const buttonSaveElement = buttonsContainerElement.querySelector('button[data-action="save"]');
  const buttonPublishElement = buttonsContainerElement.querySelector('button[data-action="publish"]');
  const autoSaveInfoElement = document.createElement('small');
  buttonsContainerElement.insertBefore(autoSaveInfoElement, buttonsContainerElement.firstChild);

  function unsavedText(date) {
    const dateString = date.toLocaleString('de-DE', {
      dateStyle: 'short',
      timeStyle: 'short',
    });
    return `Ungespeicherte Änderungen (${dateString}) <button class="button-text -gray" type="button">verwerfen</button>`;
  }

  function removeStoredValues() {
    formElementsToBeAutosaved.forEach((formElementElement) => {
      const itemName = `edit-page-${id}-${formElementElement.name}`;
      localStorage.removeItem(itemName);
    });
    localStorage.removeItem(`edit-page-${id}-latestChange`);
  }

  function updateAutoSaveText(date) {
    autoSaveInfoElement.innerHTML = unsavedText(date);
    const buttonElement = autoSaveInfoElement.querySelector('button');
    buttonElement.addEventListener('click', () => {
      formElementsToBeAutosaved.forEach((formElementElement) => {
        formElementElement.value = formElementElement.initialValue;
        if (formElementElement.simplemde) {
          formElementElement.simplemde.value(formElementElement.initialValue);
        }
      });
      updateTranslationsOrgOther();
      [...styleSelectElements].forEach((styleSelectElement) => {
        updateStyle(styleSelectElement);
      });
      removeStoredValues();
      autoSaveInfoElement.innerHTML = '';
    });
  }

  function autosave() {
    formElementsToBeAutosaved.forEach((formElementElement) => {
      const itemName = `edit-page-${id}-${formElementElement.name}`;
      const storedValue = localStorage.getItem(itemName);
      if (formElementElement.initialValue !== formElementElement.value
        && storedValue !== formElementElement.value) {
        const date = new Date();
        localStorage.setItem(itemName, formElementElement.value);
        localStorage.setItem(`edit-page-${id}-latestChange`, date);
        updateAutoSaveText(date);
      }
    });
  }

  function setStoredValues() {
    const latestChangeItem = localStorage.getItem(`edit-page-${id}-latestChange`);
    if (latestChangeItem) {
      const latestChange = new Date(latestChangeItem);
      if (element.dataset.latestUpdate && new Date(element.dataset.latestUpdate) < latestChange) {
        formElementsToBeAutosaved.forEach((formElementElement) => {
          const itemName = `edit-page-${id}-${formElementElement.name}`;
          const storedValue = localStorage.getItem(itemName);
          if (storedValue !== null && storedValue !== formElementElement.value) {
            formElementElement.value = storedValue;
            updateAutoSaveText(latestChange);
          }
        });
      } else {
        removeStoredValues();
      }
    }
  }

  function updateTranslationsOrgOther() {
    translationsOrgOtherElement.hidden = translationsOrgElement.value !== 'andere';
  }

  function updateStyle(selectElement) {
    const styleExamplePElement = selectElement.parentElement.parentElement.querySelector('.edit-translation__style-example p');
    if (styleExamplePElement) {
      let className = '';
      if (selectElement.name === 'strongTextStyle') {
        className = `-strong-text-style--${selectElement.value}`;
      }
      if (selectElement.name === 'emTextStyle') {
        className = `-em-text-style--${selectElement.value}`;
      }
      styleExamplePElement.setAttribute('class', className);
    }
  }

  function getBodyData() {
    const formData = new FormData(formElement);
    const bodyData = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of formData.entries()) {
      // eslint-disable-next-line prefer-destructuring
      bodyData[entry[0]] = entry[1];
    }
    return bodyData;
  }

  function showError(message) {
    // eslint-disable-next-line no-new
    new GlobalMessage(message, true);
  }

  function handleBody(data) {
    if (data.success) {
      if (data.reload) {
        window.location = window.location.pathname + window.location.search;
      } else if (data.redirect) {
        window.location = data.redirect;
      }
      removeStoredValues();
    } else {
      showError(data.message);
    }
  }
  function handleResponse(response) {
    if (response.ok) {
      return response.json();
    }
    showError(`Server Fehler: ${response.statusText}`);
    return false;
  }

  // handle Translation

  function deleteTranslation() {
    fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(getBodyData()),
    }).then((response) => handleResponse(response)).then((data) => {
      handleBody(data);
    }).catch((exception) => showError(`Server Fehler: ${exception.message}`));
  }

  function saveTranslation() {
    fetch(url, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(getBodyData()),
    }).then((response) => handleResponse(response)).then((data) => {
      handleBody(data);
    }).catch((exception) => showError(`Server Fehler: ${exception.message}`));
  }

  function publishTranslation() {
    const bodyData = getBodyData();
    bodyData.publish = true;
    fetch(url, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyData),
    }).then((response) => handleResponse(response)).then((data) => {
      handleBody(data);
    }).catch((exception) => showError(`Server Fehler: ${exception.message}`));
  }

  if (buttonDeleteElement) {
    buttonDeleteElement.addEventListener('click', (event) => {
      event.preventDefault();
      deleteTranslation();
    });
  }

  if (buttonSaveElement) {
    buttonSaveElement.addEventListener('click', (event) => {
      event.preventDefault();
      saveTranslation();
    });
  }

  if (buttonPublishElement) {
    buttonPublishElement.addEventListener('click', (event) => {
      event.preventDefault();
      publishTranslation();
    });
  }

  [...styleSelectElements].forEach((styleSelectElement) => {
    styleSelectElement.addEventListener('change', () => {
      updateStyle(styleSelectElement);
    });
  });

  if (translationsOrgElement) {
    translationsOrgElement.addEventListener('change', () => {
      updateTranslationsOrgOther();
    });
  }

  document.addEventListener('keydown', (event) => {
    if (event.code === 'KeyS' && event.metaKey) {
      event.preventDefault();
      saveTranslation();
    }
  });

  // autosave interval
  setStoredValues();
  updateTranslationsOrgOther();
  [...styleSelectElements].forEach((styleSelectElement) => {
    updateStyle(styleSelectElement);
  });
  setInterval(autosave, 500);
}
