import PopupForm from './PopupForm';
import NewTranslationForm from './NewTranslationForm';

class Popup {
  constructor(element) {
    const popup = this;
    const containerElement = element.querySelector('.popup__container');
    const popupFormElements = element.querySelectorAll('.popup-form');
    const newTranslationFormElements = element.querySelectorAll('.new-translation-form');

    [...popupFormElements].forEach((popupFormElement) => new PopupForm(popupFormElement, this));
    [...newTranslationFormElements].forEach((newTranslationFormElement) => new NewTranslationForm(newTranslationFormElement, this));

    this.bodyElement = document.querySelector('body');
    this.element = element;
    this.popupFormElements = popupFormElements;
    this.newTranslationFormElements = newTranslationFormElements;

    function openOrClose() {
      if (window.location.hash.substr(2) === element.id) {
        popup.open();
      } else {
        popup.close(false);
      }
    }

    element.addEventListener('click', (event) => {
      if (event.target.closest('.popup__container') !== containerElement) {
        popup.close();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        popup.close();
      }
    });
    window.addEventListener('hashchange', openOrClose);
    openOrClose();
  }

  open() {
    const {
      element,
      popupFormElements,
      newTranslationFormElements,
      bodyElement,
    } = this;
    if (element.hidden) {
      bodyElement.classList.add('-no-scrolling');
      element.hidden = false;
      let firstFormElement = false;
      // eslint-disable-next-line prefer-destructuring
      firstFormElement = popupFormElements[0];
      if (!firstFormElement && newTranslationFormElements) {
        // eslint-disable-next-line prefer-destructuring
        firstFormElement = newTranslationFormElements[0];
      }
      if (firstFormElement) {
        const focusElement = firstFormElement.querySelector('input:not([type="hidden"]), select, button[type="submit"]');
        if (focusElement) {
          focusElement.focus();
        }
      } else {
        element.focus();
      }
    }
  }

  close(updateHash = true) {
    const {
      element,
      bodyElement,
    } = this;
    if (!element.hidden) {
      bodyElement.classList.remove('-no-scrolling');
      element.hidden = true;
      if (updateHash) {
        document.location.hash = '#!';
      }
    }
  }
}

[...document.querySelectorAll('.popup')].forEach((element) => new Popup(element));

export default Popup;
