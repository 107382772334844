class Clouds {
  constructor(element) {
    const clouds = this;
    this.element = element;
    this.childElements = element.querySelectorAll('.clouds__dude, .clouds__cloud-1, .clouds__cloud-2');
    this.animationAllowed = true;

    function onScroll() {
      clouds.moveElements();
    }

    function updateEventListener(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          document.addEventListener('scroll', onScroll, {
            passive: true,
          });
          window.addEventListener('resize', onScroll, {
            passive: true,
          });
        } else {
          document.removeEventListener('scroll', onScroll);
          window.removeEventListener('resize', onScroll);
        }
      });
    }

    const observer = new IntersectionObserver(updateEventListener, {
      threshold: [0, 1.0],
    });

    setTimeout(() => {
      element.classList.remove('-before-start');
      this.animationAllowed = true;
    }, 400);

    observer.observe(element);
  }

  moveElements() {
    const {
      element,
      childElements,
      animationAllowed,
    } = this;
    const { scrollTop } = document.scrollingElement;
    const { offsetTop } = element;
    const { innerHeight } = window;
    if (animationAllowed) {
      [...childElements].forEach((childElement) => {
        let translateY = (offsetTop > innerHeight) ? (scrollTop + innerHeight - offsetTop) : scrollTop;
        translateY = translateY < -20 ? -20 : translateY;
        translateY *= childElement.dataset.intensity;
        // eslint-disable-next-line no-param-reassign
        childElement.style.transform = `translateY(${translateY}px)`;
      });
    }
  }
}

[...document.querySelectorAll('.clouds')].forEach((element) => new Clouds(element));
