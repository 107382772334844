import PopupForm from './PopupForm';

class NewTranslationForm extends PopupForm {
  constructor(element, popup) {
    super(element, popup);

    this.formLabelTopicElement = element.querySelector('.form__label.-topic');
    this.formLabelNewTopicElement = element.querySelector('.form__label.-new-topic');
    this.buttonSwitchToNewTopic = element.querySelector('button[data-action="switch-to-new-topic"]');
    this.buttonSwitchToTopic = element.querySelector('button[data-action="switch-to-topic"]');
    this.selectTopicElement = element.querySelector('select[name="topic"]');
    this.inputNewTopicElement = element.querySelector('input[name="new-topic"]');

    const subcategoryElement = element.querySelector('select[name="subcategory"]');

    subcategoryElement.addEventListener('change', (event) => {
      this.updateTopic(event.target.selectedOptions[0]);
    });

    this.buttonSwitchToNewTopic.addEventListener('click', () => {
      this.showNewTopic();
    });

    this.buttonSwitchToTopic.addEventListener('click', () => {
      this.showTopic();
    });

    this.updateTopic(subcategoryElement.selectedOptions[0]);
  }

  showNewTopic() {
    this.formLabelNewTopicElement.hidden = false;
    this.inputNewTopicElement.disabled = false;
    this.hideTopic();
  }

  hideNewTopic() {
    this.formLabelNewTopicElement.hidden = true;
    this.inputNewTopicElement.disabled = true;
  }

  showTopic() {
    this.formLabelTopicElement.hidden = false;
    this.selectTopicElement.disabled = false;
    this.hideNewTopic();
  }

  hideTopic() {
    this.formLabelTopicElement.hidden = true;
    this.selectTopicElement.disabled = true;
  }

  updateTopic(selectedOption) {
    const {
      buttonSwitchToTopic,
      selectTopicElement,
    } = this;
    selectTopicElement.innerHTML = '';
    if (selectedOption && selectedOption.dataset.topics) {
      const { uid } = selectTopicElement.dataset;
      const topics = JSON.parse(selectedOption.dataset.topics);
      topics.forEach((topic) => {
        const optionElement = document.createElement('option');
        optionElement.textContent = topic.title;
        optionElement.value = topic.uid;
        optionElement.disabled = topic.disabled;
        optionElement.selected = topic.selected;
        if (!topic.selected && uid && uid === topic.uid) {
          optionElement.selected = true;
        }
        selectTopicElement.appendChild(optionElement);
      });
      this.showTopic();
      buttonSwitchToTopic.hidden = false;
    } else {
      this.showNewTopic();
      buttonSwitchToTopic.hidden = true;
    }
  }
}

export default NewTranslationForm;
