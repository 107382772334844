import GlobalMessage from './GlobalMessage';

class ButtonFavlist {
  constructor(element) {
    const { action, translationId } = element.dataset;
    this.element = element;

    const bodyData = {
      id: translationId,
    };

    element.addEventListener('click', () => {
      if (element.classList.contains('-added')) {
        this.fetch(action, 'delete', bodyData);
      } else {
        this.fetch(action, 'put', bodyData);
      }
    });
  }

  fetch(action, method, bodyData) {
    const { element } = this;
    element.classList.add('-is-loading');
    fetch(action, {
      method,
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyData),
    }).then((response) => {
      element.classList.remove('-is-loading');
      if (response.ok) {
        return response.json();
      }
      new GlobalMessage('`Server Fehler: ${response.statusText}`', true);
      return false;
    }).then((data) => {
      if (data && data.success === true) {
        window.location.reload();
      } else {
        new GlobalMessage('Unbekannnter Fehler', true);
      }
    });
  }
}

[...document.querySelectorAll('button.button-solid.-favlist')].forEach((button) => new ButtonFavlist(button));
