import GlobalMessage from './GlobalMessage';

class ResetPasswordForm {
  constructor(element) {
    const { action } = element;
    const { method } = element.dataset;
    const submitElement = element.querySelector('button[type="submit"]');

    this.element = element;

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      submitElement.classList.add('-is-loading');
      const formData = new FormData(element);
      const bodyData = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of formData.entries()) {
        // eslint-disable-next-line prefer-destructuring
        bodyData[entry[0]] = entry[1];
      }
      [...element.querySelectorAll('.error')].forEach((errorElement) => errorElement.remove());
      let csrf = '';
      if (element.dataset.csrf) {
        // eslint-disable-next-line prefer-destructuring
        csrf = element.dataset.csrf;
      }
      fetch(action, {
        method,
        credentials: 'same-origin',
        headers: {
          'X-CSRF': csrf,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      }).then((response) => {
        submitElement.classList.remove('-is-loading');
        if (response.ok) {
          return response.json();
        }
        this.showError({
          message: `Server Fehler: ${response.statusText}`,
        });
        return false;
      }).then((data) => {
        if (data && data.success === true) {
          if (data.reload) {
            window.location = window.location.pathname;
          } else if (data.redirect) {
            window.location = data.redirect;
          } else {
            // eslint-disable-next-line no-new
            new GlobalMessage(data.message);
          }
        } else {
          this.showError(data);
        }
      });
    });
  }

  showFieldErrors(fieldErrors) {
    const { element } = this;
    let firstElementFocused = false;
    [...element.querySelectorAll('input, select')].forEach((inputElement) => {
      const { name } = inputElement;
      const labelElement = inputElement.closest('label, .form__label');
      if (labelElement) {
        const errorElement = labelElement.querySelector('.error');
        if (errorElement) {
          errorElement.remove();
        }
        if (name && fieldErrors[name]) {
          const newErrorElement = document.createElement('div');
          newErrorElement.classList.add('error');
          newErrorElement.textContent = fieldErrors[name];
          labelElement.appendChild(newErrorElement);
          if (!firstElementFocused) {
            inputElement.focus();
            firstElementFocused = true;
          }
        }
      }
    });
  }

  showError(data) {
    const { element } = this;
    if (data.data && data.data.fieldErrors) {
      this.showFieldErrors(data.data.fieldErrors);
    } else {
      const errorElement = document.createElement('div');
      errorElement.classList.add('error');
      errorElement.textContent = data.message;
      const footerElement = element.querySelector('.reset-password-form__footer');
      element.insertBefore(errorElement, footerElement);
    }
  }
}

const resetPasswordFormElement = document.querySelector('.reset-password-form');
if (resetPasswordFormElement) {
  new ResetPasswordForm(resetPasswordFormElement);
}
