class Favitem {
  constructor(element) {
    this.element = element;
    this.id = element.dataset.id;
    const buttonRemoveElement = element.querySelector('.button-translation__button-remove');

    buttonRemoveElement.addEventListener('click', (event) => {
      event.preventDefault();
      this.deleteItem();
    });
  }

  deleteItem() {
    const {
      element,
    } = this;
    element.classList.add('-is-loading');
    const bodyData = {
      id: this.id,
    };
    fetch('/portal/api/user/favlist', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyData),
      credentials: 'same-origin',
    }).then((response) => response.json()).then((data) => {
      if (data.success) {
        document.location.reload();
      }
    });
  }
}

[...document.querySelectorAll('.button-translation.-favitem')].forEach((element) => new Favitem(element));
