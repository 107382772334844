const bpTwoColumnsTranslation = 1024;

class TranslationText {
  constructor(element) {
    let buttonClicked = false;
    const markdownElement = element.querySelector('.translation-text__markdown');
    const buttonShowMoreElement = element.querySelector('.button-show-more');
    const buttonShowMoreMobileElement = element.querySelector('.button-show-more.-mobile');

    function checkHeight() {
      if (markdownElement && buttonShowMoreElement) {
        const maxHeight = markdownElement.offsetHeight + markdownElement.offsetTop;
        if (maxHeight > 1000 && !buttonClicked) {
          element.classList.add('-is-compact');
          buttonShowMoreElement.hidden = false;
        } else if (window.matchMedia(`(min-width: ${bpTwoColumnsTranslation / 16}em)`).matches) {
          element.classList.remove('-is-compact');
          buttonShowMoreElement.hidden = true;
        }
      }
    }

    function onButtoShowMoreClick(event) {
      buttonClicked = true;
      event.target.hidden = true;
      element.classList.remove('-is-compact');
    }

    checkHeight();

    window.addEventListener('resize', checkHeight, {
      passive: true,
    });

    if (buttonShowMoreMobileElement) {
      buttonShowMoreMobileElement.addEventListener('click', onButtoShowMoreClick);
    }
    if (buttonShowMoreElement) {
      buttonShowMoreElement.addEventListener('click', onButtoShowMoreClick);
    }
  }
}

export default TranslationText;
