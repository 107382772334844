class Mainnav {
  constructor(element) {
    this.element = element;
    this.bodyElement = document.querySelector('body');
    this.buttonMenuElement = element.querySelector('.button-menu');
    this.buttonSearchElement = element.querySelector('.button-search');
    this.backdropElement = document.querySelector('.mainnav-backdrop');
    this.cateogryNavElement = element.querySelector('.mainnav__category-nav');
    this.searchElement = element.querySelector('.mainnav__search');
    this.userNavUserButton = element.querySelector('.user-nav__user-button');
    this.userNavDropdownNavElement = element.querySelector('.user-nav__dropdown-nav');

    const {
      buttonMenuElement,
      buttonSearchElement,
      backdropElement,
      cateogryNavElement,
      searchElement,
      userNavUserButton,
      userNavDropdownNavElement,
    } = this;

    function onScroll() {
      if (document.scrollingElement.scrollTop > 0 && element.getBoundingClientRect().y === 0) {
        element.classList.add('-border');
      } else {
        element.classList.remove('-border');
      }
    }

    if (!element.classList.contains('-border')) {
      document.addEventListener('scroll', onScroll, {
        passive: true,
      });
      onScroll();
    }

    buttonMenuElement.addEventListener('click', () => {
      if (cateogryNavElement.hidden) {
        this.closeSearch();
        this.closeUserNavDropdown();
        this.openNav();
      } else {
        this.closeNav();
      }
    });

    buttonSearchElement.addEventListener('click', () => {
      if (searchElement.hidden) {
        this.closeNav();
        this.closeUserNavDropdown();
        this.openSearch();
      } else {
        this.closeSearch();
      }
    });

    if (userNavUserButton) {
      userNavUserButton.addEventListener('click', () => {
        if (userNavDropdownNavElement.hidden) {
          this.closeNav();
          this.closeSearch();
          this.openUserNavDropdown();
        } else {
          this.closeUserNavDropdown();
        }
      });
    }

    backdropElement.addEventListener('click', () => {
      this.closeNav();
      this.closeSearch();
      this.closeUserNavDropdown();
    });

    window.addEventListener('keydown', (event) => {
      if (event.code === 'Escape') {
        this.closeNav();
        this.closeSearch();
        this.closeUserNavDropdown();
      }
    });
  }

  openNav() {
    const {
      element,
      bodyElement,
      buttonMenuElement,
      backdropElement,
      cateogryNavElement,
    } = this;
    if (cateogryNavElement.hidden) {
      bodyElement.classList.add('-no-scrolling');
      element.classList.add('-open');
      buttonMenuElement.classList.add('-open');
      cateogryNavElement.hidden = false;
      backdropElement.classList.add('-visible');
      cateogryNavElement.focus();

      buttonMenuElement.ariaLabelDefaultText = buttonMenuElement.getAttribute('aria-label');
      buttonMenuElement.setAttribute('aria-expanded', 'true');
      buttonMenuElement.setAttribute('aria-label', 'Hauptmenü schließen');
    }
  }

  closeNav() {
    const {
      element,
      bodyElement,
      buttonMenuElement,
      backdropElement,
      cateogryNavElement,
    } = this;
    if (!cateogryNavElement.hidden) {
      bodyElement.classList.remove('-no-scrolling');
      element.classList.remove('-open');
      buttonMenuElement.classList.remove('-open');
      cateogryNavElement.hidden = true;
      buttonMenuElement.focus();

      backdropElement.classList.remove('-visible');
      buttonMenuElement.setAttribute('aria-expanded', 'false');
      buttonMenuElement.setAttribute('aria-label', buttonMenuElement.ariaLabelDefaultText);
    }
  }

  openSearch() {
    const {
      element,
      bodyElement,
      buttonSearchElement,
      backdropElement,
      searchElement,
    } = this;
    const inputElement = searchElement.querySelector('input');
    if (searchElement.hidden) {
      bodyElement.classList.add('-no-scrolling');
      element.classList.add('-open');
      buttonSearchElement.classList.add('-open');
      searchElement.hidden = false;
      backdropElement.classList.add('-visible');
      if (inputElement) {
        inputElement.focus();
      }

      buttonSearchElement.ariaLabelDefaultText = buttonSearchElement.getAttribute('aria-label');
      buttonSearchElement.setAttribute('aria-expanded', 'true');
      buttonSearchElement.setAttribute('aria-label', 'Suche schließen');
    }
  }

  closeSearch() {
    const {
      element,
      bodyElement,
      buttonSearchElement,
      backdropElement,
      searchElement,
    } = this;
    if (!searchElement.hidden) {
      bodyElement.classList.remove('-no-scrolling');
      element.classList.remove('-open');
      buttonSearchElement.classList.remove('-open');
      searchElement.hidden = true;
      buttonSearchElement.focus();

      backdropElement.classList.remove('-visible');
      buttonSearchElement.setAttribute('aria-expanded', 'false');
      buttonSearchElement.setAttribute('aria-label', buttonSearchElement.ariaLabelDefaultText);
    }
  }

  openUserNavDropdown() {
    const {
      bodyElement,
      userNavUserButton,
      backdropElement,
      userNavDropdownNavElement,
    } = this;

    if (userNavDropdownNavElement && userNavDropdownNavElement.hidden) {
      bodyElement.classList.add('-no-scrolling');
      userNavUserButton.classList.add('-open');
      userNavDropdownNavElement.hidden = false;
      backdropElement.classList.add('-visible');
      userNavDropdownNavElement.focus();

      userNavUserButton.ariaLabelDefaultText = userNavUserButton.getAttribute('aria-label');
      userNavUserButton.setAttribute('aria-expanded', 'true');
      userNavUserButton.setAttribute('aria-label', 'Benutzer-Navigation schließen');
    }
  }

  closeUserNavDropdown() {
    const {
      bodyElement,
      userNavUserButton,
      backdropElement,
      userNavDropdownNavElement,
    } = this;

    if (userNavDropdownNavElement && !userNavDropdownNavElement.hidden) {
      bodyElement.classList.remove('-no-scrolling');
      userNavUserButton.classList.remove('-open');
      userNavDropdownNavElement.hidden = true;
      backdropElement.classList.remove('-visible');
      userNavUserButton.focus();

      userNavUserButton.setAttribute('aria-expanded', 'false');
      userNavUserButton.setAttribute('aria-label', userNavDropdownNavElement.ariaLabelDefaultText);
    }
  }
}

if (document.querySelector('.mainnav')) {
  // eslint-disable-next-line no-new
  new Mainnav(document.querySelector('.mainnav'));
}
